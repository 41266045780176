<template>
  <div>
    <el-card shadow="never" style="overflow-y: scroll; height: 600px">
      <div slot="header" class="flex" style="align-items: center">
        <span>学员课程课消明细</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <!-- <el-table :size="size" :border="true" :data="tableData">
      <el-table-column align="center" prop="userName" label="学员姓名" />
      <el-table-column align="center" prop="flag" label="消费类型" />
      <el-table-column align="center" prop="amount" label="数量（课时）">
        <template slot-scope="scope">
          <span
            v-if="scope.row.amount != 0"
            :style="{
              fontWeight: 'bold',
              color: scope.row.flag == '报名' ? 'green' : 'red',
            }"
          >
            {{ scope.row.flag == "报名" ? "+" : "-" }}
          </span>
          <span>
            {{ scope.row.amount }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="courseName" label="课程">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.courseName" placement="top">
            <span class="text-oneline">
              {{ scope.row.courseName }}
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="courseGoodsName" label="课时类型" />
      <el-table-column
        align="center"
        sortable
        prop="createTime"
        label="时间"
        width="150"
      />
    </el-table> -->
      <el-table-bar fixed :static="true">
        <el-table
          :data="tableData"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '数量（课时）'">
                <span
                  v-if="scope.row.amount != 0"
                  :style="{
                    fontWeight: 'bold',
                    color: scope.row.flag == '报名' ? 'green' : 'red',
                  }"
                >
                  {{ scope.row.flag == "报名" ? "+" : "-" }}
                </span>
                <span>
                  {{ scope.row.amount }}
                </span>
              </template>
              <template v-else-if="item.label === '课程'">
                <el-tooltip :content="scope.row.courseName" placement="top">
                  <span class="text-oneline">
                    {{ scope.row.courseName }}
                  </span>
                </el-tooltip>
              </template>
              <!-- <template v-else-if="item.label === '操作'">
                <el-button
                  style="margin-left: 8px"
                  type="text"
                  @click="openDetailModal(scope.row)"
                  >课程详情</el-button
                >
              </template> -->
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import StudentCourseLogList from "./StudentCourseLogList";
import { getStudentCourseLogList } from "@/api/businesscourse/course";
import TextButton from "@/components/TextButton";
// 默认展示列

const baseColSetting = [
  {
    label: "学员姓名",
    prop: "userName",
    state: true,
    align: "left",
    width: "100",
  },
  {
    label: "消费类型",
    prop: "flag",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "数量(课时)",
    prop: "amount",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "课程",
    prop: "courseName",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "课程类型",
    prop: "courseGoodsName",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "时间",
    prop: "createTime",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  // {
  //   label: "操作",
  //   state: true,
  //   align: "center",
  //   width: "130",
  // },
];
export default {
  components: { StudentCourseLogList, TextButton },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        userName: null,
        courseGoodsName: null,
        courseName: null,
        flag: null,
        userId: null,
        courseId: null,
      }),
    },
  },
  watch: {
    searchForm: {
      handler(val) {
        this.pageInfo = {
          pageNum: 1,
          pageSize: 10,
          total: 0,
          needCount: true,
        };
        this.getListHandler();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      tableKey: 1,
      drawer: false,
      direction: "btt",
      item: {},
      size: "small",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    handleClose() {
      this.drawer = false;
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    /**
     * 获取列表
     */
    async getListHandler() {
      const { userId, courseId } = this.searchForm;
      let responseData = await getStudentCourseLogList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        userId,
        courseId,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
  },
};
</script>
<style lang="scss" scoped>
.item-title {
  height: 50px;
}
.item-text1 {
  font-weight: bold;
}
@import "../../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
@import "../../../style/container";
</style>